.search-bar-container {
  flex: 1 1 18.75rem;
  border: 1px solid #ccc;
  border-radius: 1.25rem;
  overflow: hidden;
  max-width: 600px;
  min-width: 600px;
}

.search-bar-container.modal {
  flex: 1;
  border: 1px solid #cbc4f4;
  min-height: 2rem;
  width: 100%;
}

.search-bar-input {
  border: 0;
  padding-inline-start: 0.5rem;
  flex: 1;
}

.search-bar-input[disabled] {
  cursor: not-allowed;
}

.search-bar-svg {
  padding-inline-end: 0.5rem;
}

.search-bar-container-disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
