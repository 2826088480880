.whatsapp-container {
  position: fixed;
  bottom: 10px;
  left: 20px;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
}

.whatsapp-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
}

.whatsapp-container .whatsapp-tooltip {
  position: absolute;
  background-color: rgba(255, 255, 245, 0.9);
  border: 1px solid #ccc;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  bottom: 80px;
  left: 0;
  box-shadow: 1px 7px 20px 3px #90ee90;
}

.whatsapp-container .whatsapp-tooltip-arrow {
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  top: 100%;
  left: 25px;
}

.whatsapp-container .whatsapp-tooltip-content {
  max-width: 200px;
}

.whatsapp-container .whatsapp-button {
  display: inline-block;
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
  font-size: 18px;
  min-width: 200px;
  max-width: 200px;
  height: 50px;
  max-height: 50px;
  box-shadow: 1px 1px 1px 1px var(--yellow);
}

.whatsapp-container .whatsapp-button:hover {
  color: black;
}

.whatsapp-container .whatsapp-button-arrow {
  position: absolute;
  right: 10px;
}
